@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Allura&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	color:#FFF;
	background-color:#25d366;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.imgcls{
	height: 100%;
    max-height: 600px;
    width: 100%;
}

body{
	
}

.my-float{
	margin-top:16px;
}

.stickyheader{
	background-color: #FFF;
    position:-webkit-sticky;
    position:sticky;
    top:0;
    width:100%;
    z-index:100;
}
.headerlink{
	margin:5px;
	color: #fff !important;;
	font-weight:700
}
.iconcolor :hover{
	color: #5DADE2;
}

.alink{
	color: black;
}
.alink:hover{
	color: #003d99;
}

/* footer css start */
.col_white_amrc { color:#FFF;}
footer { width:100%; background-color:#263238; min-height:250px; padding:10px 0px 25px 0px ;}
.pt2 { padding-top:40px ; margin-bottom:20px ;}
footer p { font-size:13px; color:#CCC; padding-bottom:0px; margin-bottom:8px;}
.mb10 { padding-bottom:15px ;}
.footer_ul_amrc { margin:0px ; list-style-type:none ; font-size:14px; padding:0px 0px 10px 0px ; }
.footer_ul_amrc li {padding:0px 0px 5px 0px;}
.footer_ul_amrc li a{ color:#CCC;}
.footer_ul_amrc li a:hover{ color:#fff; text-decoration:none;}
.fleft { float:left;}
.padding-right { padding-right:10px; }

.footer_ul2_amrc {margin:0px; list-style-type:none; padding:0px;}
.footer_ul2_amrc li p { display:table; }
.footer_ul2_amrc li a:hover { text-decoration:none;}
.footer_ul2_amrc li i { margin-top:5px;}

.bottom_border { border-bottom:1px solid #323f45; padding-bottom:20px;}
.foote_bottom_ul_amrc {
	list-style-type:none;
	padding:0px;
	display:table;
	margin-top: 10px;
	margin-right: auto;
	margin-bottom: 10px;
	margin-left: auto;
}
.foote_bottom_ul_amrc li { display:inline;}
.foote_bottom_ul_amrc li a { color:#999; margin:0 12px;}

.social_footer_ul { display:table; margin:15px auto 0 auto; list-style-type:none;  }
.social_footer_ul li { padding-left:20px; padding-top:10px; float:left; }
.social_footer_ul li a { color:#CCC; border:1px solid #CCC; padding:8px;border-radius:8px;}
.social_footer_ul li i {  width:20px; height:20px; text-align:center;}

/* footer css end */

/*Card CSS*/
.card-list {
	display: flex;
	flex-wrap: wrap; /* Allow cards to wrap to the next line on smaller screens */
  }
  
  .card {
	width: 350px; /* Set your fixed width for each card */
	border: 1px solid #ccc;
	cursor: pointer;
	padding: 10px;
	margin: 10px;
	transition: background-color 0.3s;
	box-sizing: border-box; /* Include padding and border in the total width/height */
  }
  
  .card:hover {
	box-shadow: 0px 0px 20px 0px #5DADE2;
  }
  
  .card-upper {
	overflow: hidden;
  }
  
  .card-upper img {
	width: 100%;
	height: auto;
  }
  
  .card-lower {
	padding: 10px;
  }
  
  /* Media query for screens smaller than 600px (adjust as needed) */
  @media (max-width: 600px) {
	.card {
	  width: calc(50% - 20px); /* Two cards per row with some margin in between */
	}
  }
  

  .header-container {
	display: flex;
	align-items: center;
	background-color: #263238; /* Set your background color */
	color: #fff; /* Set text color */
	padding: 10px;
  }

  .header-icon {
	margin-right: 10px;
  }

